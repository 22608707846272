// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";

//import styles
import '../../assets/styles/containerStyles/about.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import { InputGroup } from "../../components/uiElements/inputGroup";
import { LinkButton } from "../../components/buttons/buttons";
import { LanguageTabs } from "../../components/uiElements/Tabs";
// Import utils
import {
    changeStateField,
    getInputValues, getPatchReqData,
    getTranslatableInputValues, getTranslationData,
    initTranslations,
    toggleStateField
} from "../../utils/helperFunctions";
import { mapStateToProps } from "../../redux/mapStateToProps";
import { GetAboutData, UpdateAboutData } from "../../redux/actions";
import { validateTranslations } from "../../utils/validateTranslations";
import { ACCEPT_IMAGE_TYPES } from "../../constants/acceptedTypes";
import { generateFileMediaUrl } from "../../utils/generateMemberMediaUrl";
import ItemsList from "../../components/ItemsList";

class About extends Component {
    constructor(props){
        super(props);
        this.translationsFields = [
            'pageName',
            'title',
            'description',
            'subTitle',
            'subDescription',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                media: undefined,
                workers: '',
                service: '',
                availability: '',
                partners: '',
                certificate: '',
                inMarket: '',
            },
            errors: {},
            errorsTabs: [],
            isEditing: true,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.setAboutData = this.setAboutData.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.updateAbout = this.updateAbout.bind(this);

    }

    async componentDidMount(){
        await this.props.GetAboutData();
        const { about } = this.props;
        this.setAboutData(about)
    }

    setAboutData(about){
        const { fieldsData } = this.state;
        const translationsData = {};

        if ( about ) {
            Object.keys(fieldsData.translations).forEach(key => {
                const trItem = about.translations.find(item => item.languageId === key);
                translationsData[key] = {
                    ...fieldsData.translations[key],
                    title: trItem?.title || '',
                    pageName: trItem?.pageName || '',
                    description: trItem?.description || '',
                    subTitle: trItem?.subTitle || '',
                    subDescription: trItem?.subDescription || '',
                };
            });
        }
        const initStateData = {
            translations: translationsData,
            media: about?.media,
            workers: about?.workers || '',
            service: about?.service || '',
            availability: about?.availability || '',
            partners: about?.partners || '',
            certificate: about?.certificate || '',
            inMarket: about?.inMarket || '',
        }
        this.currentData = JSON.parse(JSON.stringify(initStateData));
        this.setState({
            fieldsData: initStateData,
            errors: {},
            errorsTabs: [],
            requestLoading: false,
        });
    }

    getMedia(mediaArray){
        const { fieldsData, errors, isEditing } = this.state;
        if ( isEditing ) {
            this.currentData?.media?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('media', mediaArray[0]?.id)
                : this.updatedDataMap.delete('media');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                media: mediaArray[0],
            },
            errors: {
                ...errors,
                media: false
            }
        })
    }

    async updateAbout(){
        await this.setState({
            requestLoading: true
        });
        const { translations, media, } = this.state.fieldsData;
        const translationRequiredFields = ['title', 'description','pageName'];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = { ...validationTr.errors };

        if ( !media ) {
            errors.media = true;
            result = false;
        }
        if ( result ) {
            const trData = getTranslationData(translations);
            const reqData = this.getPatchReqData(trData);
            this.props.UpdateAboutData(reqData).then((updatedData) => {
                this.updatedDataMap.clear();
                this.setAboutData(updatedData)
            }).catch(() => {
            }).finally(() => {
                this.setState({ requestLoading: false });
            });

        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    getListInfo(data){
        let { mainLanguage } = this.props;
        return data?.map((item,index) => {
            const { translations } = item;
            const mainTranslations = translations?.find(item => item.languageId === mainLanguage);
            const { title } = mainTranslations || {};
            return {
                id: index,
                values: [
                    title ?? '-',
                ],
            }
        });
    }

    render(){
        const { about } = this.props;
        const { fieldsData, errors, languageTab, mediaModalOpen, requestLoading, isEditing, errorsTabs } = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};
        const itemListHeaderInfo = {
            generalInfo: ["Անվանում"],
            largeItems: [0],
            actions: ["edit"],
        };
        const blocksListInfo = this.getListInfo(about?.blocks);
        return <PageWrapper pageTitle={'Մեր Մասին'}>

            <section className="about">
                <div className="top-side">
                    <label>Նկար <span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.media ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}/>
                </div>
                {fieldsData?.media && <div className={'background-image'}>
                    <img src={generateFileMediaUrl(fieldsData?.media?.path)}/>
                </div>}
                <div className="info-wrapper">
                    <div className={'numbers-part'}>
                        <div className="left-part">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={"Աշխատակիցներ"}
                                        placeholder="Աշխատակիցներ"
                                        name="workers"
                                        integerNumber={false}
                                        value={fieldsData?.workers}
                                        required={true}
                                        initValue={this.currentData?.workers}
                                        error={errors?.workers}
                                        onChange={this.getInputValues}/>

                            <InputGroup inputType="input"
                                        type="text"
                                        label={"Գործընկերներ"}
                                        placeholder="Գործընկերներ"
                                        name="partners"
                                        integerNumber={false}
                                        value={fieldsData?.partners}
                                        required={true}
                                        initValue={this.currentData?.partners}
                                        error={errors?.partners}
                                        onChange={this.getInputValues}/>

                            <InputGroup inputType="input"
                                        type="text"
                                        label={"Սերտիֆիկատ"}
                                        placeholder="Սերտիֆիկատ"
                                        name="certificate"
                                        integerNumber={false}
                                        value={fieldsData?.certificate}
                                        required={true}
                                        initValue={this.currentData?.certificate}
                                        error={errors?.certificate}
                                        onChange={this.getInputValues}/>
                        </div>
                        <div className="right-part">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={"Հասանելիություն"}
                                        placeholder="Հասանելիություն"
                                        name="availability"
                                        integerNumber={false}
                                        value={fieldsData?.availability}
                                        required={true}
                                        initValue={this.currentData?.availability}
                                        error={errors?.availability}
                                        onChange={this.getInputValues}/>

                            <InputGroup inputType="input"
                                        type="text"
                                        label={"Շուկայում"}
                                        placeholder="Շուկայում"
                                        name="inMarket"
                                        integerNumber={false}
                                        value={fieldsData?.inMarket}
                                        required={true}
                                        initValue={this.currentData?.inMarket}
                                        error={errors?.inMarket}
                                        onChange={this.getInputValues}/>

                            <InputGroup inputType="input"
                                        type="text"
                                        label={"Ծառայություն"}
                                        placeholder="Ծառայություն"
                                        name="service"
                                        integerNumber={false}
                                        value={fieldsData?.service}
                                        required={true}
                                        initValue={this.currentData?.service}
                                        error={errors?.service}
                                        onChange={this.getInputValues}/>
                        </div>
                    </div>
                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  errorsTabs={errorsTabs}
                                  activeTab={languageTab}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="էջի անունը"
                                placeholder="էջի անունը"
                                name="pageName"
                                value={currentData.pageName}
                                initValue={initData.pageName}
                                error={errors['pageName' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Վերնագիր"
                                placeholder="Վերնագիր"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Ենթավերնագիր"
                                placeholder="Ենթավերնագիր"
                                name="subTitle"
                                value={currentData.subTitle}
                                initValue={initData.subTitle}
                                error={errors['subTitle' + languageTab]}
                                maxLength={100}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="textarea"
                                type="text"
                                label="Նկարագրություն"
                                placeholder="Նկարագրություն"
                                name="description"
                                value={currentData.description}
                                initValue={initData.description}
                                error={errors['description' + languageTab]}
                                maxLength={1000}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="textarea"
                                type="text"
                                label="Ենթանկարագրություն"
                                placeholder="Ենթանկարագրություն"
                                name="subDescription"
                                value={currentData.subDescription}
                                initValue={initData.subDescription}
                                error={errors['subDescription' + languageTab]}
                                maxLength={1000}
                                onChange={this.getTranslationValues}/>
                </div>

                <div className="flex-wrapper-right">
                    <LinkButton title={'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.updateAbout}/>
                </div>

                <label className={'list-label'}>Բաժիններ</label>
                <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                           itemListInfo={blocksListInfo}
                           loading={requestLoading}
                           editLink={"/about"}/>
            </section>

            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetAboutData,
    UpdateAboutData
};

export default connect(mapStateToProps, mapDispatchToProps)(About)
